<template>
  <v-container>
    <v-row class="d-flex align-center" v-scrollfadein>
      <v-col cols="12" class="pb-0"><h3>Filtros</h3></v-col>
      <v-col cols="12" sm="6" md="3" class="pb-0">
        <v-menu
          ref="menu"
          v-model="dateSelected"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Rango de fechas"
              hint="Selecciona el rango de fechas y haz clic en OK"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              v-model="dateRangeText"
              clearable
              @click:clear="clearArray"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateSelected = false"> Cancelar </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(dates)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="pb-0">
        <!-- <v-col cols="12" class="mt-0 pt-0 mb-6"> -->
        <v-btn color="primary" @click="getCharts(true)" class="mr-2">
          <v-icon>mdi-filter</v-icon>Filtrar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="charts && hasElements(charts.pie)">
        <v-card elevation="2">
          <v-card-title class="justify-center">Temas</v-card-title>
          <Pie :info="charts.pie" />
        </v-card>
      </v-col>
      <!-- <v-col cols="12" v-if="charts && hasElements(charts.words)">
        <v-card elevation="2">
          <v-card-title class="justify-center">Temas</v-card-title>
          <WordCloud :info="charts.words" />
        </v-card>
      </v-col> -->
      <v-col cols="12" v-if="charts && hasElements(charts.stack)">
        <v-card elevation="2">
          <v-card-title class="justify-center">Atención</v-card-title>
          <StackedBar :info="charts.stack" />
        </v-card>
      </v-col>
      <v-col cols="12" v-if="charts && hasElements(charts.semicircle)">
        <v-card elevation="2">
          <v-card-title class="justify-center">Horario de respuesta</v-card-title>
          <SemiCirclePie :info="charts.semicircle" />
        </v-card>
      </v-col>
    </v-row>
    <div
      v-for="(item, index) in divs"
      :key="index"
      id="item.id"
      :style="'height: ' + item.height"
    ></div>
  </v-container>
</template>

//
<script>
// // import { mapState } from "vuex";

import Pie from "@/components/charts/Pie";
// import WordCloud from "@/components/charts/WordCloud";
import SemiCirclePie from "@/components/charts/SemiCirclePie";
import StackedBar from "@/components/charts/StackedBar";
import axios from "axios";

export default {
  components: {
    Pie,
    // WordCloud,
    SemiCirclePie,
    StackedBar,
  },
  data() {
    return {
      filteredCharts: null,
      dates: [],
      dateSelected: false,
      showSqueleton: false,
      divs: [
        {
          id: "#primero",
          height: "500px",
        },
        {
          id: "#segundo",
          height: "500px",
        },
        {
          id: "#tercero",
          height: "500px",
        },
      ],
    };
  },
  methods: {
    hasElements(array) {
      return array.length > 0;
    },
    clearArray() {
      this.dateRange = [];
    },
    sortArray(array) {
      return array.sort();
    },
    getCharts(showOverlay) {
      this.filteredCharts = null;
      if (!showOverlay) {
        this.showSqueleton = true;
      }
      this.posts = null;
      let data = new FormData();

      if (this.dates.length == 2) {
        data.append("date_start", this.dateRange[0] + " 00:00:00");
        data.append("date_end", this.dateRange[1] + " 23:59:59");
      }

      // this.posts = fbdata;

      axios
        .post("public/comments", data, { config: { showOverlay } })
        .then((response) => {
          this.showSqueleton = false;
          this.filteredCharts = response.data;
        })
        .catch(() => {
          this.showSqueleton = false;
          // console.log(error.response.data);
        });
    },
  },
  computed: {
    squeletonLoader() {
      return this.showSqueleton;
    },
    charts() {
      return this.filteredCharts;
    },
    dateRange: {
      get: function () {
        return this.sortArray(this.dates);
      },
      set: function (array) {
        this.dates = array;
      },
    },
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (text) {
        return text;
      },
    },
  },
  mounted() {
    this.getCharts(true);
  },
};
</script>
