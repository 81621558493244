<template>
  <div id="amSemiPie" ref="semiPieChart"></div>
</template>

<script>
import { mapState } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
am4core.options.autoDispose = true;

// import chartData from "@/assets/data/semiCircleData.json";

export default {
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
  methods: {
    renderChart() {
      am4core.useTheme(am4themes_animated);
      this.darkTheme
        ? am4core.useTheme(am4themes_dark)
        : am4core.useTheme(am4themes_material);

      let chart = am4core.create(this.$refs.semiPieChart, am4charts.PieChart);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.data = this.info;
      // chart.data = chartData;

      chart.radius = am4core.percent(70);
      chart.innerRadius = am4core.percent(40);
      chart.startAngle = 180;
      chart.endAngle = 360;

      let series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = "litres";
      series.dataFields.category = "country";

      series.slices.template.cornerRadius = 10;
      series.slices.template.innerCornerRadius = 7;
      series.slices.template.draggable = true;
      series.slices.template.inert = true;
      series.alignLabels = false;

      series.hiddenState.properties.startAngle = 90;
      series.hiddenState.properties.endAngle = 90;

      // chart.legend = new am4charts.Legend();

      // pieSeries.labels.template.fill = this.darkTheme ? "#FAFAFA" : "#000";
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    if (this.chart) {
      am4core.disposeAllCharts();
    }
  },
  computed: {
    ...mapState("account", ["darkTheme"]),
  },
  watch: {
    darkTheme() {
      am4core.unuseAllThemes();
      if (this.chart) {
        am4core.disposeAllCharts();
      }
      this.renderChart();
    },
  },
};
</script>
