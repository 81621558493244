<template>
  <div id="amStackedBar" ref="stackedBarChart"></div>
</template>

<script>
import { mapState } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";
am4core.options.autoDispose = true;

// import chartData from "@/assets/data/stackedBarData.json";

export default {
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
  methods: {
    renderChart() {
      am4core.useTheme(am4themes_animated);
      this.darkTheme
        ? am4core.useTheme(am4themes_dark)
        : am4core.useTheme(am4themes_material);

      var chart = am4core.create(this.$refs.stackedBarChart, am4charts.XYChart);
      chart.data = this.info;
      // chart.data = chartData;

      // Set input format for the dates
      chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

      // Create axes
      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.min = 0;

      // Create series
      function createSeries(field, name) {
        // Set up series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "date";
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText =
          "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

        // Add label
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;
        labelBullet.label.hideOversized = true;

        return series;
      }

      createSeries("atendidos", "Atendidos");
      createSeries("no_atendidos", "No atendidos");

      // Legend
      chart.legend = new am4charts.Legend();

      chart.language.locale = am4lang_es_ES;
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    if (this.chart) {
      am4core.disposeAllCharts();
    }
  },
  computed: {
    ...mapState("account", ["darkTheme"]),
  },
  watch: {
    darkTheme() {
      am4core.unuseAllThemes();
      if (this.chart) {
        am4core.disposeAllCharts();
      }
      this.renderChart();
    },
  },
};
</script>
